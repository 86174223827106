var isMobile = mobilecheck();
var current = 0,
	max = 0;
var IScroll;

// PRELOADER
$(document).ready(function() {
	
	if( mobile )
		$('.sbut').hide();
	
	if ($('img').length) {
		var images = [];

		$('img').each(function() {
			images.push($(this).attr('src'));
		});

		$({}).imageLoader({
			images: images,
			async: true,
			allcomplete: function(e, ui) {
				$(document).trigger('imgsready');
			},
			complete: function(e, ui) {}
		});
	} else {
		$(document).trigger('imgsready');
	}

	$(document).bind('imgsready', function() {

		$('#preloader').hide();

		max = $('.main-wrapper .background-rotate .bg').length - 1;

		if (max > 0) {
			setInterval(function() {

				var old = current;

				current++;
				if (current > max) {
					current = 0;
				}

				$('.main-wrapper .background-rotate .bg').eq(current).fadeIn(3000);
				$('.main-wrapper .background-rotate .bg').eq(old).fadeOut(4000);

			}, 4000);
		}

		startMusic();

		$(document).on('click', '.sbut', function() {

			if ($(this).hasClass('mute')) {
				$('.mute').hide();
				$('.unmute').fadeIn();

				clearInterval(idstart);

				idstop = setInterval(function() {

					var current = parseFloat($('#backgroud_music').prop("volume")) - 0.02;

					if (current > 0) {
						$('#backgroud_music').prop("volume", current);
					} else {
						clearInterval(idstop);
						$('#backgroud_music')[0].pause();
					}

				}, 200);

				//$('#backgroud_music')[0].pause();	
			} else {
				$('.unmute').hide();
				$('.mute').fadeIn();

				startMusic();
			}
		});


	});

	$('.cookie-container .accept').bind('click', function() {
		setCookie('cookie_policy', 1, 360);
		$('.cookie-container').fadeOut();

	});

	$('.go-back .overlay').bind('click', function() {

		if ($(window).width() <= 1000) {
			return true;
		}


		$('.go-back').css('left', '');
		$('.go-back').toggleClass('show');

	});

	$('.go-back').bind('mouseleave', function() {
		if ($(window).width() <= 1000) {
			return true;
		}

		$('.go-back').removeClass('show');

	});

	$('.go-back .overlay').bind('mouseenter mouseleave', function(e) {

		if ($(window).width() <= 1000) {
			return true;
		}

		if (!$('.go-back').hasClass('show')) {

			if (e.type == 'mouseenter') {
				$('.go-back').css('left', 'calc(100% - 72px)');
			} else {
				$('.go-back').css('left', '');
			}

		}

	});


	CheckOrientation();

	$(window).bind('resize orientationchange', function() {
		CheckOrientation();
	});

	$('.button-menu').bind('click', function() {

		$('nav').addClass('open');

	});

	$('nav .btn-close').bind('click', function() {

		$('nav').removeClass('open');

	});

	$('nav .right-side .col').bind('mouseenter', function() {

		var index = $(this).index();

		$('nav .right-side .bg ._bg').addClass('h');
		$('nav .right-side .bg ._bg.bg' + index).removeClass('h');

	});

	$('nav .right-side').bind('mouseleave', function() {

		$('nav .right-side .bg ._bg').addClass('h');
		$('nav .right-side .bg ._bg.bg0').removeClass('h');

	});

	myScroll = new IScroll('.list-wrapper', {
		scrollbars: true,
		mouseWheel: true,
		click:true,
		interactiveScrollbars: true,
		shrinkScrollbars: 'scale',
		fadeScrollbars: false
	});
	
});

function csrf_token() {
	return $('meta[name="csrf-token"]').attr('content');
}


function CheckOrientation() {
	if (!isMobile || window.orientation == undefined)
		return true;

	var viewport = document.querySelector("meta[name=viewport]");
	var viewportWidth = document.documentElement.clientWidth;

	if (window.orientation == 0 || window.orientation == 180) {
		//$('#prevent-landscape').hide();
		//Orientation = 'portrait';
	} else {
		//$('#prevent-landscape').show();
		//Orientation = 'landscape';
	}

	viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
}

function mobilecheck() {
	var check = false;
	(function(a) {
		if (/(android|ipad|playbook|silk|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v768|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true
	})(navigator.userAgent || navigator.vendor || window.opera);
	return check;
}

function printc(o) {
	console.log(o);
}



function setCookie(c_name, value, exdays) {
	var exdate = new Date();
	exdate.setDate(exdate.getDate() + exdays);

	var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
	document.cookie = c_name + "=" + c_value;
}

function getCookie(c_name) {
	var i, x, y, ARRcookies = document.cookie.split(";");
	for (i = 0; i < ARRcookies.length; i++) {
		x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
		y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
		x = x.replace(/^\s+|\s+$/g, "");

		if (x == c_name) {
			return unescape(y);
		}
	}
}

var idstart = null;
var idstop = null;

function startMusic() {
	if (!$('#backgroud_music').length) {
		return false;
	}

	$('#backgroud_music').prop("volume", 0);
	$('#backgroud_music')[0].play();
	clearInterval(idstop);

	idstart = setInterval(function() {

		var current = parseFloat($('#backgroud_music').prop("volume")) + 0.02;

		if (current <= 0.30) {
			$('#backgroud_music').prop("volume", current);
		} else {
			clearInterval(idstart);
		}

	}, 200);

}